import React from "react"
import Section from "../components/Section"
import Layout from "../components/Layout"
import marketCapChart from "../images/marketcapchart.png"
import portfolioConstruction from "../images/portfolioconstruction.jpg"
import "./Investments.scss"

const portfolioManagement = {
    title: "Investment management, specialized to your needs",
    listStart: "Warren Buffett has only two rules for managing investments:",
    list1: "Rule #1 – Never lose money.",
    list2: "Rule #2 – Never forget Rule #1.",
    p1:
        "Buffett’s rules are objective #1 at Savvy Investments & Planning. To be clear, Buffett’s rules are not literal, but aspirational – there is no reward without some measure of risk. But the rules reflect a critical mindset – never be frivolous or nonchalant about investing. At Savvy, managing your hard earned savings is serious business, and we will always treat every dollar you entrust to us as if it were our own.",
    p2:
        "Savvy provides investment management services personalized to each client’s individual needs. In establishing new accounts, Savvy employs a rigorous process to construct efficient portfolios based on proven principles. Savvy can also incorporate a client’s existing positions into a portfolio design and gradually migrate positions toward greater efficiency.",
}

const investmentPrinciples = {
    title: "Investment principles",
    list: [
        "1. Focus on long term results.",
        "2. Focus on current value and future growth potential.",
        "3. Diversify broadly for maximum resilience.",
        "4. Anticipate and adapt to evolving economic and market trends.",
        "5. Tax efficiency is pivotal to success.",
        "6. Risk management is imperative, especially in these volatile times.",
        "7. Patience and coaching contribute to long term success.",
    ],
}

const investmentProcess = {
    title: "Investment process",
    list: [
        "1. Carefully review current financial position and long term investment goals.",
        "2. Draft a personalized investment policy statement of goals and strategies.",
        "3. Develop a comprehensive investment plan.",
        "4. Construct the portfolio and establish initial investment positions.",
        "5. Monitor the portfolio.",
        "6. Monitor the markets.",
        "7. Adjust and adapt as risks and opportunities arise.",
    ],
}

const covidInvesting = {
    title: "Investing in the Covid era",
    p1:
        "The Covid crisis has created extreme disruptions and distortions in the financial markets that will have lasting impacts for years to come.  As of Q1 2021, the economy is recovering, but the extraordinary interventions by the Federal Reserve and the federal government have driven both stocks and bonds to record highs.  Moreover, the extraordinary level of money printing and stimulus spending also threaten to stimulate a resurgence of inflation.  These factors create a very challenging investment climate.  Looking forward, there will still be opportunities to be found, but Savvy believes that strategies for navigating the next decade will have to be more dynamic and creative than the predominantly passive strategies of the past.",
}

const forwardLookingStrategies = {
    title: "Forward Looking Investment Strategies",
    p1:
        "Equity returns over the next decade are likely to be considerably less than what we've enjoyed over the past decade.  While at the same time, volatility and inflation are likely to be considerably higher.  This is a difficult environment for investing but there are a number of strategies that still have the potential to excel.  These would include broad diversification, with significant allocations to such as international equities and commodities, and may also include dynamic allocation strategies, long/short complementary asset strategies, and hedged income strategies.  If you would like to learn more about how these strategies might work for your own portfolio, we would be happy to review the possibilities in a complimentary consultation.",
}

const trendImageTitle =
    "Overvaluation with Rising Volatility and Inflation = Challenging Investment Climate"

const portfolioReview = {
    title: "Portfolio review",
    lineStart:
        "If you have an existing investment account, you may want to consider a portfolio review. In an environment of infinite complexity and extreme volatility, it never hurts to get a second opinion. ",
    contactUs: "Contact us",
    lineEnd: " today for a complimentary consultation.",
}

export default function Investments({ data }) {
    return (
        <Layout activeTab="investments">
            <div className="investments__page-content">
                <div className="investments__portfolio-management-title">
                    {portfolioManagement.title}
                </div>
                <div className="investments__portfolio-management investments__page-section">
                    <div className="investments__portfolio-management-content investments__text-content">
                        <div>{portfolioManagement.listStart}</div>
                        <div>{portfolioManagement.list1}</div>
                        <div>{portfolioManagement.list2}</div>
                        <div>{portfolioManagement.p1}</div>
                        <div>{portfolioManagement.p2}</div>
                    </div>

                    <img
                        className="investments__portfolio-construction"
                        src={portfolioConstruction}
                        alt="portfolio construction example"
                    />
                </div>
                <div className="investments__investment investments__page-section">
                    <div className="investments__sections">
                        <div className="investments__section">
                            <Section
                                title={investmentPrinciples.title}
                                size="sm"
                            >
                                <div className="investments__investment-principles">
                                    {investmentPrinciples.list.map(
                                        (point, index) => (
                                            <div
                                                key={`principles-${index}`}
                                                className="investments__sections-content"
                                            >
                                                {point}
                                            </div>
                                        )
                                    )}
                                </div>
                            </Section>
                        </div>
                        <div className="investments__section">
                            <Section title={investmentProcess.title} size="sm">
                                <div className="investments__investment-process">
                                    {investmentProcess.list.map(
                                        (point, index) => (
                                            <div
                                                key={`process-${index}`}
                                                className="investments__sections-content"
                                            >
                                                {point}
                                            </div>
                                        )
                                    )}
                                </div>
                            </Section>
                        </div>
                    </div>
                    {/* <img
                        className="investments__stock-exchange"
                        src={stockInsights}
                        alt="stock exchange graph"
                    /> */}
                </div>
                <div className="investments__text-section">
                    <div className="investments__text-title">
                        {covidInvesting.title}
                    </div>
                    <div className="investments__text-content">
                        {covidInvesting.p1}
                    </div>
                </div>
                <div className="investments__text-section">
                    <div className="investments__text-title">
                        {forwardLookingStrategies.title}
                    </div>
                    <div className="investments__text-content">
                        {forwardLookingStrategies.p1}
                    </div>
                </div>
                <div className="investments__image-section">
                    <div className="investments__image-title">
                        {trendImageTitle}
                    </div>
                    <img
                        className="investments__image"
                        src={marketCapChart}
                        alt="a diagram of the GDP trend, compared to the market capitalization trend"
                    />
                </div>
                <div className="investments__portfolio-review">
                    <div className="investments__portfolio-review-card">
                        <div className="investments__portfolio-review-title">
                            {portfolioReview.title}
                        </div>
                        <div className="investments__portfolio-review-text">
                            {portfolioReview.lineStart}
                            <a
                                className="investments__contact-us"
                                href="/#contact"
                            >
                                {portfolioReview.contactUs}
                            </a>
                            {portfolioReview.lineEnd}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
